import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from './Styles/Box';
import Typography from './Styles/Typography';

function LeaderLeft({
  className,
  heading,
  headingLevel,
  text,
  textColor,
  backgroundColor,
  accentColor,
}) {
  return (
    <Styles className={className}>
      <Typography as={headingLevel} className="fancyHeadline">
        {heading}
      </Typography>
      <Typography>{text}</Typography>
    </Styles>
  );
}

const Styles = styled(Box)`
  position: relative;
  background: var(--darkPurple);
  color: var(--light);
  padding: 3em 20% 3em 0;
  top: -4em;
  border-radius: 0 100px 100px 0;
  ::before {
    content: '';
    height: 100%;
    width: 50%;
    background: var(--darkPurple);
    position: absolute;
    left: -50%;
    top: 0;
  }
  @media (min-width: 960px) {
    margin-right: 25%;
    border-radius: 0 1000px 1000px 0;
  }
`;

LeaderLeft.defaultProps = {
  heading: 'The quick brown fox.',
  headingLevel: 'h2',
  text:
    'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat iste quo fuga vel odio amet molestiae exercitationem nulla beatae totam dolore consequuntur harum cum dignissimos, quisquam, ad sed similique blanditiis? Corrupti voluptatibus, optio dolorem molestiae minus obcaecati illum et eius? Eos pariatur libero repudiandae labore, quia iste laudantium dignissimos eum.',
};

export default LeaderLeft;
