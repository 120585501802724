
		import React from 'react';

		function ArrowDownIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 384, 512">
					<path d="M368.2 307.1l-170.5 170.5c-3.14 3.14-8.226 3.14-11.37 0L15.82 307.1c-3.14-3.14-3.14-8.226 0-11.37c3.14-3.14 8.226-3.14 11.37 0l156.8 156.8V40.04c0-4.427 3.596-8.034 8.038-8.034s8.036 3.607 8.036 8.034v412.5l156.8-156.8c3.14-3.14 8.226-3.14 11.37 0C371.3 298.9 371.3 304 368.2 307.1z" />
				</svg>
			);
		}

		export default ArrowDownIcon;
	