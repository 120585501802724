import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from './Styles/Box';
import Typography from './Styles/Typography';

function LeaderCompactLeft({
  className,
  heading,
  headingLevel,
  headingClass,
  textColor,
  backgroundColor,
  accentColor,
}) {
  return (
    <Styles
      className={className}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <TypographyStyles className={headingClass} as={headingLevel}>
        {heading}
      </TypographyStyles>
    </Styles>
  );
}

const Styles = styled(Box)`
  position: relative;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'var(--darkPurple)'};
  color: ${({ textColor }) => (textColor ? textColor : 'var(--light)')};
  padding: 2em 10% 2em 0;
  top: -4em;
  border-radius: 0 100px 100px 0;
  ::before {
    content: '';
    height: 100%;
    width: 50%;
    background: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : 'var(--darkPurple)'};
    position: absolute;
    left: -50%;
    top: 0;
  }
  @media (min-width: 960px) {
    margin-right: 25%;
    border-radius: 0 1000px 1000px 0;
  }
`;

const TypographyStyles = styled(Typography)`
  margin-bottom: 0;
`;

LeaderCompactLeft.defaultProps = {
  heading: 'The quick brown fox.',
  headingLevel: 'h2',
};

export default LeaderCompactLeft;
