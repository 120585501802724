import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import Chip from '../../components/Styles/Chip';
import LeaderLeft from '../../components/LeaderLeft';
import LeaderCompactLeft from '../../components/LeaderCompactLeft';
import LeaderRight from '../../components/LeaderRight';
import Grid from '../../components/Styles/Grid';
import Section from '../../components/Section';
import Box from '../../components/Styles/Box';
import Typography from '../../components/Styles/Typography';
import BigNumber from '../../components/BigNumber';
import IconList from '../../components/IconList';
import CheckIcon from '../../components/Icons/Fa/Thin/CheckIcon';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import codeLines from '../../images/code-lines-compact.svg';
import ArrowDownIcon from '../../components/Icons/Fa/Thin/ArrowDownIcon';
import Card from '../../components/Styles/Card';
import ButtonText from '../../components/Buttons/ButtonText';

class WebDesignPage extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Boston Web Design" />
        <PageHeader
          heading="Websites Made to Super Charge Your Business"
          subHeading="ALTdev focuses on building websites that are clean, user friendly, and mobile responsive. Using our unique approach we focus on your business needs and objectives to ensure that your new site leads to more conversions."
          buttonText="Request a Quote"
          buttonLink="/contact/"
          maxWidth={960}
          margin="auto"
        />

        <SectionDark topDivider={true}>
          <Typography as="h2" textAlign="center">
            We Build Powerful Websites
          </Typography>
          <Typography textAlign="center">
            Alternative Dev is the the Web Design &amp; Development partner you
            have been looking for. Our passion for building websites makes us
            great at what we do and that means you get the best.
          </Typography>
          <PillBox gridTemplateColumns={['repeat(3, 1fr)']} gridGap={[2, 4]}>
            <Box textAlign="center">
              <BigNumber text="01" color="var(--light)" />
              <Typography as="h3">Overview</Typography>
              <ArrowDownIcon />
            </Box>
            <Box textAlign="center">
              <BigNumber text="02" color="var(--light)" />
              <Typography as="h3">Design Capabilities</Typography>
              <ArrowDownIcon />
            </Box>
            <Box textAlign="center">
              <BigNumber text="03" color="var(--light)" />
              <Typography as="h3">Our Approach</Typography>
              <ArrowDownIcon />
            </Box>
          </PillBox>
        </SectionDark>

        <Section mt="5em">
          <Grid
            gridTemplateColumns={['1fr', '1fr 1fr']}
            gridGap={4}
            alignItems="center"
          >
            <Box className="content-wrapper" mr={[0, 2, 4]} order={['2', '1']}>
              <Typography as="span" className="preHeading">
                It’s 2021, stop living in the past.
              </Typography>
              <Typography as="h2">
                Wave goodbye to outdated websites.
              </Typography>
              <Typography>
                Our unique process gives you a website that meets the latest
                development, performance &amp; accessibility standards.
              </Typography>
              <IconList
                icon={<CheckIcon />}
                list={[
                  {
                    text:
                      'Meet Web Content Accessibility Guidelines (WCAG) 2.1 standards out of the box.',
                    link: 'https://www.w3.org/WAI/standards-guidelines/wcag/',
                  },
                  {
                    text:
                      "Exceed Google's high and ever changing Core Web Vitals standards",
                    link: 'https://web.dev/vitals/',
                  },
                  {
                    text:
                      'All websites created using the latest web development standards',
                  },
                ]}
              />
            </Box>
            <Box order={['1', '2']}>
              <GatsbyImage
                image={data.macbook.childImageSharp.gatsbyImageData}
                altText="Laptop being balanced on a persons finger"
              />
            </Box>
          </Grid>
        </Section>

        <Section>
          <Grid
            gridTemplateColumns={['1fr', '1fr 1fr']}
            gridGap={4}
            alignItems="center"
          >
            <Box>
              <GatsbyImage
                image={data.mobile.childImageSharp.gatsbyImageData}
                altText="Responisve website on mobile phone"
              />
            </Box>
            <Box className="content-wrapper" ml={[0, 2, 4]}>
              <Typography as="span" className="preHeading">
                Responsive Web Design
              </Typography>
              <Typography as="h2">Mobile First Design</Typography>
              <Typography>
                More commonly referred to as responsive web design, mobile first
                design is exactly what it sounds like. 63% of all traffic online
                in the US comes from mobile devices. Using this approach creates
                a strong foundation for us to build on, making sure your site
                looks amazing on mobile, tablets, and desktops. Now and in the
                future.
              </Typography>
            </Box>
          </Grid>
        </Section>

        <Section>
          <Grid
            gridTemplateColumns={['1fr', '1fr 1fr']}
            gridGap={4}
            alignItems="center"
          >
            <Box className="content-wrapper" mr={[0, 2, 4]} order={['2', '1']}>
              <Typography as="span" className="preHeading">
                🔥 Blazing Fast
              </Typography>
              <Typography as="h2">Speed Optimization</Typography>
              <Typography>
                Slow sites cost you customers and lose you money. If your
                website takes longer than a few seconds to load you risk losing
                74% of your customers. Speed of your site is so important to
                avoid losing traffic, ensure you don't fall behind your
                competition and to maintain your reputation.
                <b class="mark">Every second counts.</b>
              </Typography>
            </Box>
            <Box order={['1', '2']}>
              <GatsbyImage
                image={data.gtmetrix.childImageSharp.gatsbyImageData}
                altText="GTmetrix Grade A Perfromace Report"
              />
            </Box>
          </Grid>
        </Section>

        <Section pb={[3, 6]}>
          <Grid
            gridTemplateColumns={['1fr', '1fr 1fr']}
            gridGap={4}
            alignItems="center"
          >
            <Box>
              <GatsbyImage
                image={data.searchconsole.childImageSharp.gatsbyImageData}
                altText="Google Search Console SEO Performance"
              />
            </Box>
            <Box className="content-wrapper" ml={[0, 2, 4]}>
              <Typography as="span" className="preHeading">
                🚀 Built for searching
              </Typography>
              <Typography as="h2">SEO Optimized</Typography>
              <Typography>
                We build your website to be fast and efficient making it easy
                for search engine crawlers, to crawl your website and index all
                your pages. Helping your website rank and perform better in
                search engines.
              </Typography>
            </Box>
          </Grid>
        </Section>

        <SectionLightBlue pb={6}>
          <LeaderCompactLeft
            heading="Our Design Capabilities"
            backgroundColor="var(--light)"
            textColor="var(--textColor)"
          />
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr']}
            gridGap={[2, 4]}
            alignItems="center"
          >
            {/* <Box>
              <Typography as="span" className="preHeading">
                🚀 Built for searching
              </Typography>
              <Typography as="h2">SEO Optimized</Typography>
              <Typography>
                We build your website to be fast and efficient making it easy
                for search engine crawlers, to crawl your website and index all
                your pages. Helping your website rank and perform better in
                search engines.
              </Typography>
            </Box> */}
            <Box>
              <BigNumber text="DX" />
              <Typography as="h3">Web Design</Typography>
              <List>
                <ListItem icon={<CheckIcon />} text="Responsive Design" />
                <ListItem icon={<CheckIcon />} text="Single Page Websites" />
                <ListItem icon={<CheckIcon />} text="Landing Pages" />
                <ListItem icon={<CheckIcon />} text="WordPress Theme Design" />
                <ListItem icon={<CheckIcon />} text="Prototyping" />
              </List>
            </Box>
            <Box>
              <BigNumber text="UX" />
              <Typography as="h3">User Experience (UX)</Typography>
              <List>
                <ListItem
                  icon={<CheckIcon />}
                  text="Information Architecture"
                />
                <ListItem icon={<CheckIcon />} text="Accessibility" />
                <ListItem icon={<CheckIcon />} text="Design Style Guides" />
                <ListItem icon={<CheckIcon />} text="Mockups" />
                <ListItem
                  icon={<CheckIcon />}
                  text="User Interface (UI) Design"
                />
              </List>
            </Box>
            {/* <Box>
              <BigNumber text="DEV" />
              <Typography as="h3">Web Development</Typography>
              <List>
                <ListItem
                  icon={<CheckIcon />}
                  text="PSD to HTML (HTML5, CSS3, JS)"
                />
                <ListItem icon={<CheckIcon />} text="Accessibility" />
                <ListItem icon={<CheckIcon />} text="Design Style Guides" />
                <ListItem icon={<CheckIcon />} text="Mockups" />
                <ListItem
                  icon={<CheckIcon />}
                  text="User Interface (UI) Design"
                />
              </List>
            </Box>
            <Box>
              <BigNumber text="WP" />
              <Typography as="h3">WordPress</Typography>
              <List>
                <ListItem icon={<CheckIcon />} text="WordPress Theme Design" />
                <ListItem icon={<CheckIcon />} text="Content Updates" />
                <ListItem icon={<CheckIcon />} text="Plugin Updates" />
                <ListItem
                  icon={<CheckIcon />}
                  text="Migration CMS to WordPress"
                />
                <ListItem
                  icon={<CheckIcon />}
                  text="Static HTML to WordPress"
                />
                <ListItem
                  icon={<CheckIcon />}
                  text="WordPress security audit"
                />
              </List>
            </Box> */}
          </Grid>
        </SectionLightBlue>

        <SectionGradient
          bottomDivider={true}
          dividerBottomFill="var(--lightBlue)"
        >
          <LeaderCompactLeft
            heading="Our Approach"
            backgroundColor="var(--light)"
            textColor="var(--textColor)"
          />
          <CardApproach mb={4}>
            <Grid
              gridTemplateColumns={['1fr', '1fr 1fr']}
              gridGap={4}
              alignItems="center"
            >
              <Box>
                <Typography as="h2">Template Based Websites</Typography>
                <GatsbyImage
                  image={data.templates.childImageSharp.gatsbyImageData}
                  altText="GTmetrix Grade A Perfromace Report"
                />
              </Box>
              <Box>
                <Chip>Fast Turn-Around</Chip>
                <Chip>Affordable</Chip>
                <Typography as="span" className="preHeading">
                  Starting at $1,500
                </Typography>
                {/* <Typography>
                  Suspendisse massa, velit in commodo viverra at aliquam.
                  Elementum consequat, nec, tellus maecenas nam facilisis magna
                  vulputate sapien.
                </Typography> */}
                <List>
                  <ListItem
                    icon={<CheckIcon />}
                    text="140+ Beautiful Templates To Choose From"
                  />
                  <ListItem
                    icon={<CheckIcon />}
                    text="Fully Responsive Website"
                  />
                  <ListItem
                    icon={<CheckIcon />}
                    text="Standard Page Load Optimization"
                  />
                  <ListItem icon={<CheckIcon />} text="On-Page SEO" />
                  <ListItem icon={<CheckIcon />} text="Fast Turn-Around" />
                  <ListItem
                    icon={<CheckIcon />}
                    text="Google Analytics & Google Webmaster Configuration"
                  />
                </List>
                {/* <ButtonText>Learn More</ButtonText> */}
              </Box>
            </Grid>
          </CardApproach>
          <CardApproach>
            <Grid
              gridTemplateColumns={['1fr', '1fr 1fr']}
              gridGap={4}
              alignItems="center"
            >
              <Box>
                <Typography as="h2">Custom Designed Websites</Typography>
                <GatsbyImage
                  image={data.customDesign.childImageSharp.gatsbyImageData}
                  altText="GTmetrix Grade A Perfromace Report"
                />
              </Box>
              <Box>
                <Chip>Fast Turn-Around</Chip>
                <Chip>Affordable</Chip>
                <Typography as="span" className="preHeading">
                  Starting at $3,000
                </Typography>
                {/* <Typography>
                  Suspendisse massa, velit in commodo viverra at aliquam.
                  Elementum consequat, nec, tellus maecenas nam facilisis magna
                  vulputate sapien.
                </Typography> */}
                <List>
                  <ListItem
                    icon={<CheckIcon />}
                    text="100% Custom Designed Website"
                  />
                  <ListItem
                    icon={<CheckIcon />}
                    text="Fully Responsive Website"
                  />
                  <ListItem
                    icon={<CheckIcon />}
                    text="In-Depth Page Load Optimization"
                  />
                  <ListItem
                    icon={<CheckIcon />}
                    text="Accessibility Optimization"
                  />
                  <ListItem icon={<CheckIcon />} text="On-Page SEO" />
                  <ListItem icon={<CheckIcon />} text="Fast Turn-Around" />
                  <ListItem
                    icon={<CheckIcon />}
                    text="Google Analytics & Google Webmaster Configuration"
                  />
                </List>
                {/* <ButtonText>Learn More</ButtonText> */}
              </Box>
            </Grid>
          </CardApproach>
        </SectionGradient>
      </Layout>
    );
  }
}

const SectionDark = styled(Section)`
  color: white;
  background: var(--darkPurple);
`;

const SectionLightBlue = styled(Section)`
  background: var(--lightBlue);
  /* :after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 759px;
    height: 741px;
    background: url(${codeLines}) no-repeat;
  } */
`;

const CardApproach = styled(Card)`
  .preHeading {
    font-size: 1.5em;
  }
  .gatsby-image-wrapper {
    margin-bottom: -32px;
    margin-left: -32px;
  }
  h2,
  ${Chip} {
    margin-bottom: 0.5em;
  }
  ${Chip}:not(:last-of-type) {
    margin-right: 0.5em;
  }
`;

const SectionGradient = styled(Section)`
  color: white;
  background: var(--linearGradientMixed);
`;

const PillBox = styled(Grid)`
  border-radius: 10px;
  background: var(--purple);
  color: var(--light);
  padding: 1em;
  margin-bottom: -5em;
  h3 {
    font-size: 1em;
  }
  & > :nth-child(2) {
    border-left: 1px solid #1a0c4445;
    border-right: 1px solid #1a0c4445;
  }
  & > div > svg {
    width: 1em;
    fill: var(--seafoam);
  }
  @media (min-width: 960px) {
    & > div > svg {
      width: 1.2em;
    }
    h3 {
      font-size: 1.5em;
    }
    border-radius: 1000px;
    padding: 1em 5em;
  }
`;

export default WebDesignPage;

export const query = graphql`
  query {
    searchconsole: file(
      relativePath: {
        eq: "stephen-phillips-hostreviews-co-uk-shr_Xn8S8QU-unsplash.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    templates: file(
      relativePath: { eq: "fd56bdd7f7c932234648c5edbb47d36a.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    customDesign: file(
      relativePath: { eq: "figma-altdev-custom-design-library.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    mobile: file(
      relativePath: { eq: "webfactory-ltd-CqjibEeTUts-unsplash.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    macbook: file(relativePath: { eq: "hdfhg_52_pkdsfpok1.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
    }
    gtmetrix: file(relativePath: { eq: "altdev-GTmetrix.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
    }
  }
`;
